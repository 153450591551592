/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useRef, useState } from "react";
import "./container.css";
import "./header.css";
import "./cart.css";
import "./test.css";

function App() {
  const navRef = useRef<HTMLDivElement>(null);
  const [widthNav, setWidthNav] = useState<number | undefined>(0);
  const [showMobileCart, setShowMobileCart] = useState(false);
  const [windowWidthIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    setWidthNav(navRef.current?.offsetWidth);
    return () => {};
  }, []);


  const TagsItem = ({ stylesName = "" }: { stylesName?: string }) => {
    return (
      <div className={`tags ${stylesName}`}>
        <span className="tag tag-yellow">24 - 72 Hour Results</span>
        <span className="tag tag-grey">Blood Sample</span>
        <span className="tag tag-orange">Fasting Required</span>
        <span className="tag tag-green">Baseline Panel</span>
      </div>
    );
  };

  return (
    <div className="react__container_app">
      {/* HEADER NAV */}
      {true && (
        <section className="react__header_container">
          <div className="title_nav_step">
            <h1 className="">Find Products & Services</h1>
            <p className="sub_title_step">
              {"Houston (Medical Center), TX Location >"}
            </p>
          </div>

          <div ref={navRef} className="nav_step">
            {/* SHOW-HIDDEN SUCCESS STEPS */}
            {false && (
              <div className="progress_confirm" style={{ width: widthNav }}>
                <div className="">
                  <i className="icon_checked_success"></i> Appointment Confirmed
                </div>
              </div>
            )}
            <div className="progress_step"></div>
            <div className="item_nav item_nav_ok">
              <div className="number_item_step number_item_step_ok">1</div>
              <div className="name_item_step">Search</div>
            </div>
            <div className="item_nav">
              <div className="number_item_step">2</div>
              <div className="name_item_step">Schedule</div>
            </div>
            <div className="item_nav">
              <div className="number_item_step">3</div>
              <div className="name_item_step">Forms</div>
            </div>
            <div className="item_nav">
              <div className="number_item_step">4</div>
              <div className="name_item_step">Chechout</div>
            </div>
          </div>
        </section>
      )}

      {/* CART */}
      {true && (
        <section
          className="react__cart_container"
          style={{
            height: !showMobileCart ? "" : "70%",
          }}
        >
          <div className="react__container_cart">
            <div
              className="react__cart_line_show_mobile"
              onClick={() => {
                setShowMobileCart(!showMobileCart);
              }}
            >
              3 Items * $177 Subtotal
            </div>
            <div className="react__button_view_cart">
              <button className="" type="button">
                Review Cart
              </button>
              <button className="" type="button">
                continue to...
              </button>
            </div>

            <div
              className="react__cart_steps"
              style={{
                display:windowWidthIsMobile ? showMobileCart ? "block" : "none" : "block"
              }}
            >
              {/* TOTAL TEST*/}
              <Fragment>
                <h3 className="react__title_cart">
                  <div className="react__title_cart_step">1</div>Cart | 3 items
                </h3>
                <div className="react__container_item_cart">
                  {[1, 2].map((res) => {
                    return (
                      <div className="react__item_cart">
                        <div className="react__item_cart_info">
                          <p className="react_cart_item_name">
                            Complete Blood Count Complete
                          </p>
                          <p className="react__cart_item_price">
                            <p>$49.00</p> <p>x</p>
                          </p>
                        </div>
                        <TagsItem stylesName="react__tag_cart" />
                        <div className="react__cart_line"></div>
                      </div>
                    );
                  })}
                  <div className="react__item_cart">
                    <div className="react__item_cart_info react__subtotal_cart">
                      <p className="react_cart_item_name">Subtotal</p>
                      <p className="react__cart_item_price">
                        <p>$177.00</p>
                      </p>
                    </div>

                    <div className="react__item_cart_info react__subtotal_cart">
                      <p className="react_cart_item_name">Extimated Taxes</p>
                      <p className="react__cart_item_price">
                        <p>$11.00</p>
                      </p>
                    </div>

                    <div className="react__item_cart_info react__total_cart">
                      <p className="react_cart_item_name">Total</p>
                      <p className="react__cart_item_price">
                        <p>$188.00</p>
                      </p>
                    </div>
                  </div>
                </div>
              </Fragment>

              {/* TEST DAY & TIME*/}
              <Fragment>
                <h3 className="react__title_cart">
                  <div className="react__title_cart_step">2</div>Day & Time
                </h3>
                <div className="react__container_item_cart">
                  {[1, 2].map((res) => {
                    return (
                      <div className="react__item_cart">
                        <div className="react__item_cart_info">
                          <p className="react_cart_item_name react__cart_day_time">
                            Currently Selected
                            <div className="react__day_time">
                              Tuesday, March 26th, 2024 * 3:00PM
                            </div>
                          </p>
                        </div>
                        <div className="react__cart_line react__cart_line_day_time"></div>
                      </div>
                    );
                  })}
                </div>
              </Fragment>

              {/* INFORMATION & FORMS*/}
              <Fragment>
                <h3 className="react__title_cart">
                  <div className="react__title_cart_step">3</div>Information &
                  Forms
                </h3>
                <div className="react__container_item_cart">
                  {[
                    {
                      name: "Your Information",
                      percent: 100,
                      complete: "Complete",
                    },
                    {
                      name: "Additional Information",
                      percent: 73,
                      complete: "Min Left",
                    },
                  ].map((res) => {
                    return (
                      <div className="react__item_cart">
                        <div className="react__item_cart_info">
                          <p className="react_cart_item_name react__name_form">
                            <div
                              className={
                                res.percent >= 100
                                  ? "react__title_cart_step_ok"
                                  : "react__title_cart_step_pending"
                              }
                            >
                              {/* <i className="react__icon_success"></i> */}
                            </div>
                            {res.name}
                          </p>
                          <p className="react__cart_item_price">
                            <p>
                              {res.percent}%{" "}
                              {res.percent >= 100
                                ? `${res.complete}`
                                : `* ${res.complete}`}
                            </p>
                          </p>
                        </div>
                        <div className="react__cart_line react__cart_line_day_time"></div>
                      </div>
                    );
                  })}
                </div>
              </Fragment>

              <div className="react__button_submit_cart">
                <button className="" type="button">
                  continue to...
                </button>
              </div>
            </div>
          </div>
        </section>
      )}

      {true && (
        <section className="react__test_container">
          <div className="react__container_filter">
            <div className="react__filter_items react__container_search">
              <i className="react__search_icon"></i>
              <input
                type="text"
                className="react__input_filter"
                placeholder="Search..."
              />
            </div>

            <div className="react__filter_items">
              <div className="react__container_filters">
                {[1, 4].map((res) => {
                  return (
                    <div className="react__add_filter">
                      <div className="react__close_filter">x</div>
                      <div className="react__filter_name">
                        General Health {res}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="">
                <p className="react__number_filters">Filter(0)</p>
              </div>
              <i className="react__filter_icon"></i>
            </div>
          </div>

          <div className="container-cards">
            {[1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => {
              return (
                <div className="item-card">
                  <div className="test-title-price">
                    <h3>
                      Comprehensive Metabolic Panel <p>{"Learn More >"}</p>
                    </h3>
                    <h3 className="price">$49</h3>
                  </div>

                  <p className="info-test">
                    Provides valuable information about kidney function, liver
                    function, electrolytes, blood sugar, and more.
                  </p>

                  <TagsItem />
                  <div className="price-add">
                    <button className="add-to-cart">
                      <i className="icon-price-add"></i>
                      Add to Cart
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </div>
  );
}

export default App;
